import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, AsyncValidator } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap  } from 'rxjs/operators';
import { UserService} from '@app/service';

@Injectable({ providedIn: 'root' })
export class UserEmailValidators {

 constructor(private _userService: UserService) {}

  userEmailValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      return this._userService.checkUseremailExist(control.value)
        .pipe(
          map(res => {
            // if username is already taken
            if (res && !res.isExist ) {
              // return error
              return { 'userEmailExists': true};
            }
          })
        );
    };
  }
}

@Injectable({ providedIn: 'root' })
export class IsEmailExistValidator {
    constructor(private _userService: UserService) {}
    isEmailExistValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
          return this._userService.checkUseremailExist(control.value)
            .pipe(
              map(res => {
                // if username is already taken
                if (res && res.isExist ) {
                  // return error
                  return { 'emailExists': true};
                } 
              })
            );
        };
      }
}
