import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordMatch } from '@app/validators/password.validator';
import {TranslateService} from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, UserService } from '@app/service';
import { map,first } from 'rxjs/operators';
import {NgbModal, NgbActiveModal,  NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reset-pwd',
  templateUrl: './reset-pwd.component.html',
  styleUrls: ['./reset-pwd.component.css']
})
export class ResetPwdComponent implements OnInit {

    token = "";
    newPwd = "";
    confirmPwd = "";
    resetPwdForm: FormGroup;
    showResetForm = false;
    reset = false;
    
    constructor(private formBuilder: FormBuilder,
    public translate: TranslateService,
    private route: ActivatedRoute,private router: Router,
    private userService: UserService,
    public alerService: AlertService, 
    public NgModalService:NgbModal,
    public activeModal: NgbActiveModal,
    public config: NgbModalConfig
    ) { }

    ngOnInit() {        
        this.token = this.route.snapshot.paramMap.get('token');
        this.resetPwdForm = this.formBuilder.group({
                  newPwd: ['', [Validators.required, Validators.minLength(6)]],
                  confirmPwd: ['', [Validators.required]]
            },{
                validator: PasswordMatch('newPwd', 'confirmPwd')
            }
        );
        
        if( this.route.snapshot.data.details.valid ) {
            this.showResetForm = true;
        } else {
            this.alerService.error("invalid.reset.link",false);
        }
    }

    resetPassword() {
            //alert("Hi");
         // stop here if form is invalid
         if (this.resetPwdForm.invalid) {
             return;
         }
        
        this.userService.resetPassword(this.resetPwdForm.controls.newPwd.value, this.resetPwdForm.controls.confirmPwd.value,this.token).pipe(first()).subscribe(
            data => {
                this.alerService.success("Password has been reset successfully",true);
                setTimeout (() => {
                    this.alerService.remove();
                    this.reset = true;
                    this.router.navigate(['/login']);
                },1000);
                
            },
            error => {
                this.alerService.success("Error occured while reset password,Please try again",true);
                this.router.navigate(['/login']);
            },
        );
        this.router.navigate(['/login']);
    }
    
     canExit() : boolean {
         if ( this.reset ) {
             return true;
         } else {
            return false;
        }
     }

}
