import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataService {
    public data: any;
    public jobPriority = {
        'NOT_IMPORTANT': 'Not_Important',
        'MEDIUM_IMPORTANCE': 'Medium_Importance',
        'IMPORTANT': 'Important',
        'URGENT': 'Urgent',
    };
    public constructor() { }
}
