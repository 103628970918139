import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {
    private error: BehaviorSubject<{ errorCode: number; errorMessage: string; }> = new BehaviorSubject(null);
    $error: Observable<{ errorCode: number; errorMessage: string; }> = this.error.asObservable();

    setError(error: { errorCode: number; errorMessage: string; }) {
        console.log('Error occurred', error);
        this.error.next(error || null);
    }
}
