import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';

import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule} from '@angular/forms';
import { AutocompleteModule } from '@app/components/autocomplete/autocomplete.module';
import { AutocompleteComponent } from '@app/components/autocomplete/autocomplete.component';
import { FocusedDirective } from '@app/directives/focus-list.directive';
import { SidepanelModule } from '@app/sidepanel/sidepanel.module';
import { SidepanelComponent } from '@app/sidepanel/sidepanel.component';
import { AddjobComponent } from '@app/property/addjob/addjob.component';
import { FileDirective } from '@app/directives/file.directive';
import { FileDropDirective } from '@app/directives/file-drop.directive';
import { AddpropertyComponent } from '@app/property/addproperty/addproperty.component';
import {TranslateModule} from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdduserComponent } from '@app/property/adduser/adduser.component';
import { EdituserComponent } from '@app/property/edituser/edituser.component';
import { DeleteuserComponent } from '@app/property/deleteuser/deleteuser.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteModule,
    SidepanelModule,
    TranslateModule,
    NgbModule
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    SidepanelComponent,
    AddjobComponent,
    AutocompleteComponent,
    FocusedDirective,
  ],
  declarations: [

    HeaderComponent,
    SidebarComponent,
    SidepanelComponent,
    AddjobComponent,
    AutocompleteComponent,
    FocusedDirective,
    FileDirective,
    FileDropDirective,
    AddpropertyComponent,
    AdduserComponent,
    EdituserComponent,
    DeleteuserComponent
  ],
  providers: [
    FocusedDirective,
    FileDirective,
    FileDropDirective
  ]
})
export class LayoutModule { }
