import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-property',
  templateUrl: './import-property.component.html',
  styleUrls: ['./import-property.component.css']
})
export class ImportPropertyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
