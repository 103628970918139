import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractControl,ValidationErrors,ValidatorFn, AsyncValidatorFn, AsyncValidator } from '@angular/forms';
import { Observable, timer } from 'rxjs';
import { map, switchMap  } from 'rxjs/operators';
import { UserService} from '@app/service';

export function confirmTextValidator(nameRe: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = control.value.toLowerCase() == nameRe;
    //alert(forbidden);
    return forbidden ? null : {confirmtext: {value: control.value}};
  };
}
