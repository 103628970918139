import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService, UserService } from '@app/service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    features: any;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        //console.log("-->"+JSON.stringify(currentUser.token));
        
        if (currentUser) {

            // authorised so return true
            //console.log("----->"+JSON.stringify(currentUser));
            
            
                this.userService.getUserFeatures().then(
                    data => {
                        this.features = data;
                        localStorage.setItem('features', JSON.stringify(this.features));
                    },
                    error => {
                       
                    }
                );
            
            
            return true;
        }
       
        // not logged in so redirect to login
        this.router.navigate(['/login']);//, { queryParams: { returnUrl: state.url }}
        return false;
    }

}
