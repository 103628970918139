import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray  } from '@angular/forms';
import { UserService } from '@app/service/user.service';
import { AlertService } from '@app/service/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subject, merge, OperatorFunction} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, filter} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { UserValidators } from '@app/validators/username.validator';
import { IsEmailExistValidator } from '@app/validators/useremail.validator';

@Component({
  selector: 'app-adduser',
  templateUrl: './adduser.component.html',
  styleUrls: ['./adduser.component.css']
})
export class AdduserComponent implements OnInit {

    @Input() data;
    isDataLoaded = false;
    properties: any;
    contractor = "";
    title = "";
    userForm: FormGroup;
    name = "";
    username="";
    email="";
    password="";
    viewproperty= "";
    viewdiaries= "";
    viewinvoice= "";
    viewcertificate= "";
    viewuser= "";
    edituser= "";
    adduser= "";
    deleteuser= "";
    viewjob= "";
    features: any;
    featuresList: any;
    selectedFeature = [];
    @Output() formUpdate = new EventEmitter();
    @Output() parentUpdate = new EventEmitter();

    public constructor(private formBuilder: FormBuilder,
        private userService: UserService,
        private alertService: AlertService,
        private service: UserValidators,
        private emailService: IsEmailExistValidator,
        public translate: TranslateService
    ) {
    
    }

    ngOnInit() {
        //alert(JSON.stringify(this.data.features.features));
        this.featuresList = this.data.features;
        this.userForm = this.formBuilder.group({
              name: ['',[Validators.required]],
              username: ['',[Validators.required],this.service.userValidator()],
              email: ['',[Validators.required, Validators.email],this.emailService.isEmailExistValidator()],
              password: ['',[Validators.required, Validators.minLength(6)]],
              features: this.createFeatures(this.featuresList.features),
              selectedFeature: [''],
        });
        
    }

    createFeatures(features) {
        const arr = features.map(hobby => {
          return new FormControl();
        });
        return new FormArray(arr);
    }

    updateFeature(i, id) {
        if( id != '' && id != 'null' ) {
            this.selectedFeature[i] = id;
        }
    }

    checkValid() {
        //alert(JSON.stringify(this.userForm.controls.email.errors));
        if( this.userForm.valid ) {
         //alert("Hi");
            this.formUpdate.emit(true);
        } else {
            this.formUpdate.emit(false);
        }
    }

    submitForm() {
       
        this.userService.add(this.userForm.value).then(
            data => {
                this.alertService.success("User created successfully", false);
                setTimeout (() => {
                    console.log("timeout");
                    this.alertService.remove();
                    this.formUpdate.emit('success');
                },1000);
            },
            error => {
                this.alertService.error(error.error.message, false);
                this.formUpdate.emit('error');
            }
        );
    }
    
    handleChange() {
      
    }

    /*formatter = (x: {description: string}) => x.description;
    getTitles() {

        this.propertyService.getTitles(this.contractor).then(data => {
             this.titles = data;
        });
    }*/
    
}
