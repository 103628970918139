
import {
  Component,
  ViewChildren,
  QueryList,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl
} from '@angular/forms';
import { PropertiesService } from '@app/service/properties.service';
import { map,first } from 'rxjs/operators';
import { FocusedDirective } from '@app/directives/focus-list.directive';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteComponent {

  tabIndex = 1;

  serial = true;

  enabledCash = true;

  inputValue = '';

  mousedown = false;

  visibility = false;
  result: any;
  modelValue = '';
  @Input() formName: FormGroup;
  @Input() searchInputName: string;
  @Input() results: any;
  @Input() searchKey: string;
  @Input() selectedContractor: string;
  @Input() isDataLoaded: boolean;
  @Output() contractor = new EventEmitter();
  loader = false;
  selectedItem: any;
  constructor(private cdr: ChangeDetectorRef, private builder: FormBuilder,private propertyService: PropertiesService) {
      //alert(this.searchKey);
       this.formName = this.builder.group({
            'search': ['',Validators.required],
            'description': ['',Validators.required],
       });
  }

  @ViewChildren("options") options: QueryList<ElementRef>;

  @HostListener("keydown", ["$event"]) arrowKey(e) {
    if (this.visibility) {
      if (e.key === "ArrowUp" && this.tabIndex > 0) {
        this.tabIndex -= 1;
        this.focusElement(this.tabIndex, "previous", this.options);
    } else if (e.key === "ArrowDown" && (this.tabIndex < this.result.length-1)) {
        this.tabIndex += 1;
        this.focusElement(this.tabIndex, "next", this.options);
      }
    }
    this.cdr.markForCheck();
  }

  @HostListener("keyup.Enter", ["$event"]) onEnter(e) {
    if( this.visibility ) {
      this.onEnterSelect(e);
    } else { 
      this.onSearch(e);
    }
  }

  @HostListener("document:click") private onClick() {
    this.hide()
  }

  @HostListener("keyup.Escape") onEscape(){
    this.hide();
  }

  ngOnInit() {
      this.result = this.results;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.serial) {
        this.assignIndex(this.options);
      }
    });

  }

  assignIndex(queryList: QueryList<ElementRef>) {
    queryList.toArray().forEach((obj, index) =>
    (obj.nativeElement.tabIndex = this.tabIndex + index + 1));
  }

  show() {
    this.visibility = true;
  }

  hide() {
    this.visibility = false;
  }

  onEnterSelect(e) {
   
    
      /*var a = document.getElementById('option_'+this.tabIndex)["value"].split("_");
      this.modelValue = a[0];
      this.inputValue = a[1];
      this.hide();*/

      document.getElementById('option_'+this.tabIndex).click();
      
    
  }

  onSearch(e) {
    this.show();
    const text = e.target.value;
    this.loader = true;
    if( this.searchInputName == 'search') {
      this.propertyService.getAllProperty(text,this.selectedContractor).then(data => {
              this.result = data.properties;
              this.loader = false;
          },
          error => {

              this.loader = false;
          }
      );
    } else if( this.searchInputName == 'description') {
        //this.result = this.results;
        this.result = this.results.filter(obj => {
            console.log("on Search 3 =>"+ JSON.stringify(obj[this.searchKey]));
            return obj[this.searchKey].toLowerCase().indexOf(text.toLowerCase()) >= 0;
            //startsWith(text.toLowerCase())
        }
      );
    }
    this.loader = false;
    /*if( this.result[this.tabIndex] ) {
        const obj = this.result[this.tabIndex];
        if (obj) {
          this.onSelect(obj);
        }
    }*/
  
    /*const text = e.target.value;
    console.log("on Search 1 => "+ this.results);
    if (text.length == 0) {
        console.log("on Search 2 => "+ text);
        return this.hide();
    }
    // this.tabIndex = 0;
    this.result = this.results.filter(obj => {
            console.log("on Search 3 =>"+ JSON.stringify(obj[this.searchKey]));
            return obj[this.searchKey].toLowerCase().indexOf(text.toLowerCase()) >= 0;
            //startsWith(text.toLowerCase())
        }
    );
    console.log("on Search 3 => "+ this.results.length);
    this.result.length > 0 ? this.show() : this.hide();
    this.cdr.markForCheck();*/
    
    this.cdr.markForCheck();
  }

  onSelect(obj) {
      //alert("I"+obj.id);
      if( this.searchInputName == 'search' ) {
        this.modelValue = obj.id;
        //this.value = obj[this.searchKey];
        this.inputValue= obj.fullname;
        if( obj.addressline1 ) {
          this.inputValue += ", " + obj.addressline1;
        }
        if( obj.addressline2 ) {
          this.inputValue += ", " + obj.addressline2;
        }
        if( obj.addressline3 ) {
          this.inputValue += ", " + obj.addressline3;
        }
        if( obj.town ) {
          this.inputValue += ", " + obj.town;
        }
        if( obj.postcode ) {
          this.inputValue += ", " + obj.postcode;
        }
        //this.contractor.emit(obj.contractors);
      } else {
        this.modelValue = obj.id;
        this.inputValue= obj.description;
      }
      
      this.hide();
    
  }

  focusElement = (tabIndex: number, method: string, queryList: QueryList<ElementRef>) => {
    let relatedTabIndex = 0;
    if (method === 'next') {
       relatedTabIndex = tabIndex + 1;
    }
    else if (method === 'previous') {
      relatedTabIndex = tabIndex - 1;
    }
    const element = queryList.find(elm => elm.nativeElement.tabIndex === relatedTabIndex);
    if (element) {
      if (element.nativeElement.disabled) {
        this.focusElement(relatedTabIndex, method, queryList);
      }
      this.selectedItem = element.nativeElement.value;
      element.nativeElement.focus();
    }
    else {
      if (method ==='next'){
        if ( relatedTabIndex < queryList.length ) {
          this.focusElement(relatedTabIndex + 1, 'next', queryList)
        }
        else if (relatedTabIndex > queryList.length) {
          this.focusElement(0, 'next', queryList);
        }
      }
      else if (method === 'previous') {
        if (relatedTabIndex > 0) {
          this.focusElement(relatedTabIndex - 1, 'previous', queryList);
        }
        else if (relatedTabIndex > -1) {
          this.focusElement(relatedTabIndex + queryList.length - 1 , 'next', queryList);
        }
      }
    }
  }
}
