import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/service';
import { apiUrl,redirectToAfterLogin } from '@app/config';
import { map,first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    jwtToken: string;
    details: any;
    constructor(
        private router: Router,
        private activateRoute: ActivatedRoute,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        var currentUser = this.authenticationService.checkLogin();
        this.jwtToken = route.queryParams.token;
        console.log("-:"+this.jwtToken+""+currentUser);
        if( this.jwtToken !== undefined && currentUser) {
            this.details = this.authenticationService.decodeToken(this.jwtToken,'jwt');

            if( this.details !== undefined && currentUser) {
                this.details.pipe(first()).subscribe(
                data => {
                    if( data.hasOwnProperty('tokenData')) {
                        console.log("im am ---> "+JSON.stringify(data));
                        if( data.tokenData.hasOwnProperty('ClientName')) {
                            console.log("Hello");
                            let name = data.tokenData.ClientName;
                            let count = data.tokenData.Count;
                            localStorage.setItem('currentUser', JSON.stringify(data.user));
                            localStorage.setItem('token', JSON.stringify(data.user.token));
                            localStorage.setItem('jwtToken', JSON.stringify(this.jwtToken));
                            if( data.showImport ) {

                                this.router.navigate(['/property/connect'],{ queryParams: { showImport:  data.showImport, contractor: data.tokenData.ClientName, count: data.tokenData.Count} });
                            } else {
                                this.router.navigate([redirectToAfterLogin],{ queryParams: { showImport:  data.showImport, contractor: data.tokenData.ClientName, token: this.jwtToken}});
                            }

                        }
                    }
                }
                );
            } else {
                return true;
            }
        } else {
            
            console.log("--> in else");
            if (currentUser) {
                this.router.navigate([redirectToAfterLogin])
                return false;
            } else {
                return true;
            }
        }
    }

}
