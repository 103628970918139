import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from '@app/service/authentication.service.ts';

import { Property } from '@app/model';
import { apiUrl } from '@app/config';

interface Titles {
    id: number;
    description: any;
}

@Injectable({ providedIn: 'root' })
export class PropertiesService {
    token: any;
    constructor(private http: HttpClient,private authenticationService: AuthenticationService) {
        this.token = this.authenticationService.currentUserValue;
    }

    getByCustomerId(id: any) {
        let apiName = this.getApiUrl('api/v1/getdata');
        var params = {customerId: id, type: ["job"]};
        return this.http.post(apiName,params);
    }

    getImportPropertyStatus() {
        let apiName = this.getApiUrl('api/v1/getImportPropertyStatus');
        return this.http.get(apiName);
    }

    getProperty(id: number) {
        let apiName = this.getApiUrl(`api/v1/property/${id}`);
        return this.http.get(apiName);
    }

    getContractorList() {
        let apiName = this.getApiUrl(`api/v1/listcontractor`);
        return this.http.get(apiName);
    }

    importProperty(jwtToken: string,skipImport: number ) {
        let apiName = this.getApiUrl('api/v1/importproperties');
        var params = {jwtToken: jwtToken, skipImport: skipImport};
        return this.http.post(apiName,params);
    }

    getApiUrl(path: string) {
       let url = path + '?token='+this.token;
       return url;
    }
    
    async postProperty(propertyData) {
        var params = {
            addressline1: propertyData.addressline1,
            addressline2: propertyData.addressline2,
            addressline3: propertyData.addressline3,
            companyname: propertyData.companyname,
            contractor: propertyData.contractor,
            iscompany: propertyData.iscompany,
            landline: propertyData.landline,
            mobile: propertyData.mobile,
            name: propertyData.name,
            postcode: propertyData.postcode,
            surname: propertyData.surname,
            title: propertyData.title.id,
            town: propertyData.town,
            uprn: propertyData.uprn,
        };
        let apiName = this.getApiUrl(`api/v1/sendProperty`);
         var asyncResult = await  this.http.patch<any>(apiName,params,{}).toPromise();
         return asyncResult;
    }

    async getAllProperty(search: string, contractor: string) {
        let apiName = this.getApiUrl('api/v1/listProperties');

       var asyncResult = await this.http.get<Property>(apiName+'&search='+search+'&filterParam='+contractor).toPromise();
       console.log('No issues, I will wait until promise is resolved..');
       return asyncResult;
   }

   getProperties(search: string, contractor: string) {
        let apiName = this.getApiUrl('api/v1/listProperties');

       return this.http.get<Property>(apiName+'&search='+search+'&filterParam='+contractor);
   }
   
   async getTitles(contractor) {
        let apiName = this.getApiUrl(`api/v1/titles`);
        apiName += '&filterParam='+contractor;
        var asyncResult = await this.http.get<Titles>(apiName).toPromise();
        console.log('No issues, I will wait until promise is resolved..');
        return asyncResult;
    }

}
