import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';


  interface CacheContent {
    expiry: number;
    value: any;
  }
@Injectable({ providedIn: 'root' })

export class CacheService {
  constructor(private router: Router) {
  }
  

  private subject = new Subject<any>();

  set(message: any) {
      console.log("here in succcess alert");
      this.subject.next({ key: message.id, text: message.text });
  }

  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }

}
