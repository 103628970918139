import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { User } from '@app/model';
import { apiUrl } from '@app/config';

@Injectable({ providedIn: 'root' })
export class CountryService {
    
    constructor() {
    }
    
    getCountryCode() {
        var user = JSON.parse(localStorage.getItem('currentUser'));
        var code = '';
        var country = user ? user.country : 'uk';
        switch(country) { 
            case 'United Kindom': { 
                code = "uk";
                break; 
            } 
            case 'USA': { 
                code = "us";
                break; 
            } 
            default: { 
               code = 'uk';
              break; 
            } 
        }
        
        return code;
    }
    
    getCurrencyCode(country) {
        var code = '';
        
        switch(country) { 
            case 'uk': { 
                code = "£";
                break; 
            } 
            case 'us': { 
                code = "$";
                break; 
            } 
            default: { 
               code = '£';
               break; 
            } 
        }
        
        return code;
    }
    
    
    getLangCode() {
        var user = JSON.parse(localStorage.getItem('currentUser'));
        var code = '';
        var country = user ? user.country : 'uk';
        switch(country) { 
            case 'United Kindom': { 
                code = "en-GB";
                break; 
            } 
            case 'USA': { 
                code = "en-US";
                break; 
            } 
            default: { 
               code = 'en-GB';
              break; 
            } 
        }
        
        return code;
    }
}
