import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PagedData } from '@app/model/paged-data';
import { Job } from '@app/model/Job';
import { Page } from '@app/model/page';

const data = {
  "length": 18,
  "job_estimate": [
    {
      "number": 1,
      "description": "Customform demo job",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 12,
      "description": "Fix the boiler",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 13,
      "description": "Service the boiler",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 14,
      "description": "Job demo",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 36,
      "description": "Test",
      "status": "completed",
      "completedOnDateTime": "2018-01-08T05:15:40+0000",
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 37,
      "description": "Abc",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 46,
      "description": "Test job",
      "status": "completed",
      "completedOnDateTime": "2018-07-12T13:21:48+0000",
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 47,
      "description": "Plumbing work",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 48,
      "description": "test job",
      "status": "completed",
      "completedOnDateTime": "2018-07-27T13:11:02+0000",
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 49,
      "description": "do this",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 58,
      "description": "Plumbing job",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 61,
      "description": "Test nf 500 error",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 66,
      "description": "Issue wrong job file aatached to job",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 69,
      "description": "Test",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 71,
      "description": "Wrong jobs linking",
      "status": "completed",
      "completedOnDateTime": "2018-09-14T10:56:56+0000",
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 75,
      "description": "Cd11",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 90,
      "description": "test job",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    },
    {
      "number": 157,
      "description": "Test",
      "status": "ongoing",
      "completedOnDateTime": null,
      "contractor": "App client to get data as for local commusoft no working for php 7"
    }
  ]
};
const companyData = data;

/**
 * A server used to mock a paged data result from a server
 */
@Injectable()
export class ResultsService {
  /**
   * A method that mocks a paged server response
   * @param page The selected page
   * @returns {any} An observable containing the employee data
   */
  public getResults(page: Page): Observable<PagedData<Job>> {
    return of(companyData).pipe(map(d => this.getPagedData(page)));
  }

  /**
   * Package companyData into a PagedData object based on the selected Page
   * @param page The page data used to get the selected data from companyData
   * @returns {PagedData<CorporateEmployee>} An array of the selected data and page
   */
  private getPagedData(page: Page): PagedData<Job> {
    const pagedData = new PagedData<Job>();
    page.totalElements = companyData.length;
    page.totalPages = page.totalElements / page.size;
    const start = page.pageNumber * page.size;
    const end = Math.min(start + page.size, page.totalElements);
    for (let i = start; i < end; i++) {
      const jsonObj = companyData[i];
      const employee = new Job(jsonObj.number, jsonObj.description, jsonObj.status, jsonObj.contractor);
      pagedData.data.push(employee);
    }
    pagedData.page = page;
    return pagedData;
  }
}
