import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray  } from '@angular/forms';
import { UserService } from '@app/service/user.service';
import { AlertService } from '@app/service/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subject, merge, OperatorFunction} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, filter} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { UserValidators } from '@app/validators/username.validator';
import { IsEmailExistValidator } from '@app/validators/useremail.validator';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.css']
})
export class EdituserComponent implements OnInit {

    @Input() data;
    isDataLoaded = false;
    properties: any;
    contractor = "";
    title = "";
    userForm: FormGroup;
    name = "";
    username="";
    email="";
    password="";
    viewproperty= "";
    viewdiaries= "";
    viewinvoice= "";
    viewcertificate= "";
    viewuser= "";
    edituser= "";
    adduser= "";
    deleteuser= "";
    viewjob= "";
    features: any;
    featuresList: any;
    selectedFeature = [];
    id = "";
    @Output() formUpdate = new EventEmitter();
    @Output() parentUpdate = new EventEmitter();

    public constructor(private formBuilder: FormBuilder,
        private userService: UserService,
        private alertService: AlertService,
        private service: UserValidators,
        private emailService: IsEmailExistValidator,
        public translate: TranslateService
    ) {
       
    }

    ngOnInit() {
        this.featuresList = this.data.features;
        this.userForm = this.formBuilder.group({
              name: ['',[Validators.required]],
              username: ['',[Validators.required]],
              email: ['',[Validators.required, Validators.email]],
              //password: ['',[Validators.required, Validators.minLength(6)]],
              features: this.createFeatures(this.featuresList.features),
              selectedFeature: [''],
        });
        
        this.id = this.data.value.id;
        this.name = this.data.value.fullname;
        this.email = this.data.value.email;
        this.username =  this.data.value.username;
        this.password = this.data.value.password;
        if( this.data.value.features ) {
            this.selectedFeature = this.data.value.features;
        }
    }

    checkIfExist(id) {
      if( this.selectedFeature ) {
        if( this.selectedFeature.indexOf(id) > -1 ) {
            return id;
        }
      } else {
        return false;
      }
    }

    createFeatures(features) {
        const arr = features.map(hobby => {
          return new FormControl();
        });
        return new FormArray(arr);
    }

    updateFeature(id,event,i) {
      if( this.selectedFeature.length == 0 ) {
        this.selectedFeature.push(id);
      } else if( this.selectedFeature && this.selectedFeature.indexOf(id) === -1 ) {
        this.selectedFeature.push(id);
      } else if( this.selectedFeature.indexOf(id) > -1) {
         if( !event.target.checked ) {
            delete this.selectedFeature[this.selectedFeature.indexOf(id)];
         }
      }
    }
    
    setSelectedFeatures() {
        
    }

    checkValid() {
        //alert(JSON.stringify(this.userForm.controls.email.errors));
        if( this.userForm.valid ) {
         //alert("Hi");
            this.formUpdate.emit(true);
        } else {
            this.formUpdate.emit(false);
        }
    }

    submitForm() {
       
        this.userService.edit(this.userForm.value,this.id).then(
            data => {
                this.alertService.success("User updated successfully", false);
                setTimeout (() => {
                    console.log("timeout");
                    this.alertService.remove();
                    this.formUpdate.emit('success');
                },1000);
            },
            error => {
                this.alertService.error(error.error.message, false);
                this.formUpdate.emit('error');
            }
        );
    }
    
    handleChange() {
      
    }

    /*formatter = (x: {description: string}) => x.description;
    getTitles() {

        this.propertyService.getTitles(this.contractor).then(data => {
             this.titles = data;
        });
    }*/
    
    
    
}
