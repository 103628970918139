import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PropertiesService } from '@app/service/properties.service';
import { JobService } from '@app/service/job.service';
import { AlertService } from '@app/service/alert.service';
import { filesExt, previewImage } from '@app/config';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subject, merge, OperatorFunction} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, filter} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { featureMap } from '@app/config';

@Component({
  selector: 'app-addjob',
  templateUrl: './addjob.component.html',
  styleUrls: ['./addjob.component.css']
})
export class AddjobComponent implements OnInit {

   @Input() data;
   isDataLoaded = false;
   properties: any;
   contractor = "";
   priority = "";
   propertyForm: FormGroup;
   files: any;
   isFileupload = false;
   invalidFile = false;
   listContractors:any;
   listContractorsFeatures:any;
   descriptions:any;
   description = "";
   notes = "";
   reference = "";
   url: any;
   search = "";
   isPreview = false;
   searchFailed = false;
   searchFailedJob = false;
   searching = false;
   searchingJob = false;
   @ViewChild('instance') instance: NgbTypeahead;
   focus$ = new Subject<string>();
   click$ = new Subject<string>();
   accessDenied = false;
   
   @Output() formUpdate = new EventEmitter();

    public constructor(private formBuilder: FormBuilder,
        private propertyService: PropertiesService,
        private jobService: JobService,
        private alertService: AlertService,
        public translate: TranslateService
    ) {
    
    }

    ngOnInit() {
        this.listContractors = this.data.contractors.contractors;
        this.listContractorsFeatures = this.data.contractors.contractorFeatures;
        console.log("HI-->"+JSON.stringify(this.listContractorsFeatures));
        this.propertyForm = this.formBuilder.group({
              description: ['', Validators.required],
              notes: [''],
              files: [''],
              //filesData: [''],
              uploadfile: [''],
              priority: [''],
              reference: [''],
              search:['', [Validators.required]],
              contractor:['', [Validators.required]],
        });

        /*this.propertyService.getAllProperty('all', 'all').then(data => {
               // alert(JSON.stringify(data));
                this.properties = data.properties;
                this.isDataLoaded = true;
            }
        );*/
        
    }

    getContractor($event) {
      this.listContractors = $event;
      //alert(JSON.stringify(this.listContractors));
    }


    checkValid() {
        if( this.propertyForm.valid) {
            this.formUpdate.emit(true);
        } else {
            this.formUpdate.emit(false);
        }
    }

   /*searchDescriptions = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.descriptions.filter(v => v.description.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )*/

  formatter = (x: {description: string}) => x.description;


  /*searchDescriptions = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
        switchMap(term =>
        
          this.searcJobDescService(term).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }))
        ),
        tap(() => this.searching = false)
      )
    }*/
    
    
    /*searchDescriptions = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searchingJob = true),
        switchMap(term =>
          this.searcJobDescService(term).pipe(
            tap(() => this.searchFailedJob = false),
            catchError(() => {
              this.searchFailedJob = true;
              return of([]);
            }))
        ),
        tap(() => this.searchingJob = false)
      )*/
    
    searchDescriptions = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;
        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
          map(term => (term === '' ? this.descriptions
            : this.descriptions.filter(v => v.description.toLowerCase().indexOf(term.toLowerCase()) > -1)))
        );
      }
  
    searcJobDescService(term: string) {
          return this.jobService.getJobDescriptionSearch(this.contractor,term).pipe(
              map(response => response)
           );
     }

  
    getDescriptions() {

      /*this.propertyService.getAllProperty('all', this.contractor).then(data => {
               // alert(JSON.stringify(data));
                this.properties = data.properties;
                this.isDataLoaded = true;
            }
        );*/
        var jobfeatureId = featureMap['/add/job'];
        if( this.listContractorsFeatures[this.contractor].features && this.listContractorsFeatures[this.contractor].features[jobfeatureId] == 0) {
            this.accessDenied = true;
        } else {
            this.accessDenied = false;
            this.jobService.getJobDescription(this.contractor).then(data => {
             this.descriptions = data;
            });
        }
    }

    searchProperty = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.searching = true),
        switchMap(term =>
          this.searchPropertyService(term).pipe(
            tap(() => this.searchFailed = false),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }))
        ),
        tap(() => this.searching = false)
      )
  
    searchPropertyService(term: string) {
          return this.propertyService.getProperties(term, this.contractor).pipe(
              map(response => response.properties)
           );
     }

    formatterP = (x: {fullname: string, addressline1: string,addressline2: string, addressline3: string}) => {
        
        
          if( x.fullname != undefined ) {
            let pname = x.fullname.concat(' ',x.addressline1);
            if(x.addressline2 ) {
              
              pname.concat(' ', x.addressline2);
            }
            if(x.addressline3) {
            
              pname.concat(' ', x.addressline3);
            }

            return pname;
          }
        
    }

    callUploadFile() {
        $("#uploadfile").click();
    }

    filesDropped(files : FileList) {
        this.isFileupload = true;
        const formData: FormData = new FormData();
        this.files = files;
        this.isPreview  = false;
        this.invalidFile = false;
        for (let i = 0; i < files.length; i++) {
            var ext = files[i].name.split('.').pop();
            //alert("herer"+JSON.stringify(filesExt));
            if( filesExt.includes(ext)) {
                if( previewImage.includes(ext)) {
                    this.isPreview = true;
                } else {
                    this.isPreview = false;
                }
                //alert(filesExt);
                const reader = new FileReader();
                //formData.append('filename',files)
                this.propertyForm.patchValue({'uploadfile': files[i].name});
                reader.onload= (e) => {
                    //formData.append('files[]',reader.result);
                    if( this.isPreview ) {
                        this.url = reader.result;
                    } else {
                        //this.url = "/assets/images/nopreview.png";
                    } 
                    this.propertyForm.patchValue({'files': reader.result});
                    this.propertyForm.patchValue({'filesData[i]': reader.result});
                }
                reader.readAsDataURL(files[i]);
            } else {
               this.invalidFile = true;
            }
        }
        //alert(formData);
        /*this.jobService.uploadFile(formData)
          .subscribe(event => {
            console.log('done')
        });*/
    }
    
    submitForm() {
        this.jobService.postJob(this.propertyForm.value).subscribe(
            data => {
                this.alertService.success("Job created successfully", false);
                setTimeout (() => {
                    console.log("timeout");
                    this.alertService.remove();
                },1000);
            },
            error => {
                this.alertService.error(error.error.message, false);
            }
        );
    }

    getResult(r) {

       let name = r.fullname + ' ' + r.addressline1;
       if( r.addressline2 != undefined && r.addressline2 != '' ) {
          name += r.addressline2;
       }
       if(r.addressline3 != undefined && r.addressline3 != '') {
          name += r.addressline3;
       }

       return name;
    }
    

}
