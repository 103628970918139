
import { NgModule, ApplicationRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LetterBoldPipe } from '@app/pipes/letter-bold.pipe';
import { SearchFilterPipe } from '@app/pipes/searchfilter.pipe';
import { ClickOutsideDirective } from '@app/directives/dropdown.directive';
import { NgbModule, NgbTypeaheadModule  } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FocusedDirective } from '@app/directives/focus-list.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2SearchPipeModule,

  ],
  declarations: [
    ClickOutsideDirective,
    ////SearchFilterPipe,
    //LetterBoldPipe
  ],
  providers: [
    //ApiService

  ],
  bootstrap: []
})
export class AutocompleteModule {
  /*constructor(public appRef: ApplicationRef) {};
  hmrOnDestroy(store) {
    let cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // remove styles
    removeNgStyles();
  }
  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
}*/
}
