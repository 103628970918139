import { Injectable } from '@angular/core';
import { UserService } from '@app/service/user.service';

import { Resolve } from '@angular/router';

import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable()
export class ResetPwdViewResolver implements Resolve<any> {
  constructor(private userService: UserService) {}

  resolve(route: ActivatedRouteSnapshot) {
    //alert(route.params.token);
    return this.userService.validateResetLink(route.params.token);
  }
}
