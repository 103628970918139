import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PropertiesService } from '@app/service/properties.service';
import { AlertService } from '@app/service/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of, Subject, merge, OperatorFunction} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, tap, switchMap, filter} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import { featureMap } from '@app/config';

@Component({
  selector: 'app-addproperty',
  templateUrl: './addproperty.component.html',
  styleUrls: ['./addproperty.component.css']
})
export class AddpropertyComponent implements OnInit {

    @Input() data;
    isDataLoaded = false;
    properties: any;
    contractor = "";
    title = "";
    propertyForm: FormGroup;
    name = "";
    surname="";
    addressline1="";
    addressline2="";
    addressline3="";
    postcode="";
    town="";
    landline="";
    mobile="";
    uprn="";
    listContractors:any;
    listContractorsFeatures:any;
    companyname = '';
    iscompany = '';
    showCompany = false;
    accessDenied = false;
    titles:any;
   @ViewChild('instance') instance: NgbTypeahead;
   focus$ = new Subject<string>();
   click$ = new Subject<string>();


    @Output() formUpdate = new EventEmitter();
    @Output() parentUpdate = new EventEmitter();

    public constructor(private formBuilder: FormBuilder,
        private propertyService: PropertiesService,
        private alertService: AlertService,
        public translate: TranslateService
    ) {

    }

    ngOnInit() {
        this.listContractors = this.data.contractors.contractors;
        this.listContractorsFeatures = this.data.contractors.contractorFeatures;
        this.propertyForm = this.formBuilder.group({
              surname: [''],
              companyname: [''],
              iscompany:[''],
              name: [''],
              title: [''],
              addressline1: ['',Validators.required],
              addressline2: [''],
              addressline3: [''],
              postcode: [''],
              town: [''],
              uprn: [''],
              landline: [''],
              mobile: [''],
              contractor:['', [Validators.required]],
        });
        this.setCompanyValidators();
        this.propertyService.getAllProperty('all','all').then(data => {
               // alert(JSON.stringify(data));
                this.properties = data.properties;
                this.isDataLoaded = true;
            }
        );

    }

    getContractor($event) {
      this.listContractors = $event;
      //alert(JSON.stringify(this.listContractors));
    }

    checkValid() {
        if( this.propertyForm.valid && (this.surname !='' || this.companyname !='')) {
            this.formUpdate.emit(true);
        } else {
            this.formUpdate.emit(false);
        }
    }

    submitForm() {

        this.propertyService.postProperty(this.propertyForm.value).then(
            data => {
                this.alertService.success("Property created successfully", false);
                setTimeout (() => {
                    console.log("timeout");
                    this.alertService.remove();
                    this.formUpdate.emit('success');
                },1000);
            },
            error => {
                this.alertService.error(error.error.message, false);
                this.formUpdate.emit('error');
            }
        );


    }
    
    handleChange() {
        if( this.iscompany) {
            this.showCompany = true;
        } else {
            this.showCompany = false;
        }
    }

    setCompanyValidators() {
        const companyControl = this.propertyForm.get('companyname');
        const surnameControl = this.propertyForm.get('surname');
        

        this.propertyForm.get('iscompany').valueChanges
          .subscribe(userCategory => {

            if (userCategory === true) {
              companyControl.setValidators([Validators.required]);
              surnameControl.setValidators(null);
            } else {
              companyControl.setValidators(null);
              surnameControl.setValidators([Validators.required]);
            }

            
            companyControl.updateValueAndValidity();
            surnameControl.updateValueAndValidity();
          });
    }

    formatter = (x: {description: string}) => x.description;
    getTitles() {
        var propertyfeatureId = featureMap['/add/property'];
        if( this.listContractorsFeatures[this.contractor].features && this.listContractorsFeatures[this.contractor].features[propertyfeatureId] == 0) {
            this.accessDenied = true;
        } else {
            this.accessDenied = false;
            this.propertyService.getTitles(this.contractor).then(data => {
                 this.titles = data;
            });
        }
    }
    
    searchTitles = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
        const inputFocus$ = this.focus$;

        return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
          map(term => (term === '' ? this.titles
            : this.titles.filter(v => v.description.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
        );
    }

}
