import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { CountryService } from '@app/service/country.service';
import { UserService } from '@app/service/user.service'
import { AuthenticationService } from '@app/service/authentication.service'
import { featureMap } from '@app/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  //providers:[UserService]
})
export class AppComponent {
  title = 'Customer Portal';
  showHeader = false;
  showSidebar = false;
  showFooter = false;
  pagetitle: string;
  public identity;
  public token;
  features: any;
  previousUrl = "";
  url = "";

  constructor(
  	//private _userService:UserService
    public router: Router, 
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public countryService: CountryService,
    public userService: UserService,
    public authenticationService: AuthenticationService
  ){
  	//this.identity = this._userService.getIdentity();
  	//this.token = this._userService.getToken();
    translate.addLangs(['en-GB', 'fr', 'en-US']);
    
    //translate.setDefaultLang('en-GB');

    //const browserLang = translate.getBrowserLang();
    if( this.countryService.getLangCode() ) {
        this.translate.use(this.countryService.getLangCode());
    }
    //translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit(){
    //this.test = faCoffee;
  	console.log("app.component loaded");
    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            
            if( event.url.indexOf('/property/connect') == -1 ) { 
                this.features = localStorage.getItem('features');
                if( this.features  ) {
                    this.url = event.url;
                    console.log(" ** URL **  "+this.url);
                    if( this.url.indexOf('?') > -1) {
                        this.url = this.url.substring(0,this.url.indexOf('?'));
                    } else if( this.url.includes('/property/view') ) {
                        this.url = '/property/view';
                    } else if( this.url.includes('/job/view') ) {
                        this.url = '/job/view';
                    } else if( this.url.includes('/estimate/view') ) {
                        this.url = '/estimate/view';
                    }
                    if( this.features.indexOf(featureMap[this.url]) > -1 ) {
                    } else {
                        // user not having access 
                        this.router.navigate(['/error']);
                        
                    }
                 } else {
                    const currentUser = this.authenticationService.currentUserValue;
                    if(currentUser) {
                        this.userService.getUserFeatures().then(
                            data => {
                                this.features = data;
                                localStorage.setItem('features', JSON.stringify(this.features));
                            },
                            error => {
                               
                            }
                        );
                    }
                    // user not having access 
                    this.router.navigate(['/error']);
                    
                }
            } else {
                const currentUser = this.authenticationService.currentUserValue;
                if(currentUser) {
                    this.userService.getUserFeatures().then(
                        data => {
                            this.features = data;
                            localStorage.setItem('features', JSON.stringify(this.features));
                        },
                        error => {
                           
                        }
                    );
                }
            }
           
            this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
            this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
            this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
            this.pagetitle = this.activatedRoute.firstChild.snapshot.data.pagetitle;
            this.previousUrl = event.url;
        }
    });

    
  }
}
