import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map,first } from 'rxjs/operators';

import { User,Response } from '@app/model';
import { apiUrl,redirectToAfterLogin, adminApiUrl, featureMap, adminAPIKey } from '@app/config';
import * as jwt_decode from 'jwt-decode';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from './alert.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private data: Object;
    loading = true;

    constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router,private alertService: AlertService) {
        //alert(localStorage.getItem('token'));
        if( localStorage.getItem('token') !== 'undefined' ) { 
            //alert("In");
            this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('token')));
            this.currentUser = this.currentUserSubject.asObservable();
        }
    }

    public get currentUserValue()  {
        if( this.currentUserSubject ) {
            return this.currentUserSubject.value;
        } else {
            return null;
        }
        
    }

    login(username: string, password: string, rememberme: number) {
        //console.log("Error");
        //this.alertService.error("eeee",false);
        //this.router.navigate(['/propertyList']);
        return this.http.post<any>('api/v1/login', { email: username, password, rememberme });
    }

    register(user) {
        return this.http.post<any>('api/v1/register',user);
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        localStorage.removeItem('pageLength');
        localStorage.removeItem('pageLength_properties');
        localStorage.removeItem('pageLength_diary');
        localStorage.removeItem('pageLength_invoice');
        localStorage.removeItem('pageLength_certificate');
        localStorage.removeItem('contractorName');
        localStorage.removeItem('jobDetails');
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('features');
         localStorage.setItem('logout','yes');
        this.currentUserSubject.next(null);
    }

    /*validateToken(token: string) {
        //TODO: should we call server api and validate? now validating here
        console.log("herer 111"+token);
        var decoded = jwt_decode(token);
        console.log("herer"+decoded);
        return decoded;
    }*/


    decodeToken(token: string, type: string) {
        
        if( token ) {
            if( type === 'jwt' ) {
            //alert("here");
                // if user comes from Sharedlink
                let apiName = 'api/v1/validateJwtToken';
                var params = {token: token};
                return this.http.post(apiName,params);
            } else if( type === 'cookie' ) {
                let apiName = 'api/v1/validateCookie';
                var params = {'token': token};
                return this.http.post(apiName,params);
            }
        }
    }
    
    getCountry() {
        let apiName = adminApiUrl + "lookupcountries.json?token="+adminAPIKey;
        
        return this.http.get(apiName);
    }
    
    checkLogin() {
         if( localStorage.getItem('token') && localStorage.getItem('token') != '' && localStorage.getItem('token') !=   'null') 
         {
            return true;
         } else {
            return false;
         }
    }
    
    checkPermission(access) {
        const features = localStorage.getItem('features');
        if( features.indexOf(featureMap[access]) > -1 ) {
            return true;
        } else {
            return false;
        }
    }
}
