import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, LoginGuard, DeactivateGuard  } from '@app/guard';
import { LogoutComponent } from './logout/logout.component';
import { ErrorComponent } from './error/error.component';
import { ResetPwdComponent } from './reset-pwd/reset-pwd.component';
import { ResetPwdViewResolver } from '@app/resolvers/resetpwd.view.resolver.ts';

const routes: Routes = [
    {
     path: '',
     redirectTo: "/login",
     pathMatch: 'full',
     data: { showHeader: false, showSidebar: false, showFooter: false },
     canActivate: [LoginGuard]
   },
   {
       path:"login",
       loadChildren: './login/login.module#LoginModule',
       data: { showHeader: false, showSidebar: false, showFooter: false },
       canActivate: [LoginGuard]
   },
   {
       path:"error",
       component: ErrorComponent,
       data: { showHeader: true, showSidebar: true, showFooter: false },
       canActivate: [AuthGuard]
   },
   {
       path:"",
       loadChildren: './property/property.module#PropertyModule',
       data: { showHeader: true, showSidebar: true},
       canActivate: [AuthGuard]
   },
   { 
        path: 'logout', component: LogoutComponent,
        data: { showHeader: false, showSidebar: false, showFooter: false },
   },
   { 
        path: 'resetPassword/:token', component: ResetPwdComponent,
        data: { showHeader: false, showSidebar: false, showFooter: false },
        resolve: { details: ResetPwdViewResolver},
        canDeactivate:[DeactivateGuard] 
   }
    /*{
        path:"propertyView",
        loadChildren: './property/property.module#PropertyModule',
        data: { showHeader: true, showSidebar: true, pagetitle: 'Properties'},
        canActivate: [AuthGuard]
    }*/
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
   ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
