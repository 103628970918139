/**
 * A model for an individual corporate employee
 */
export class Job {
  number: string;
  description: string;
  status: string;
  date: string;

  constructor(number: string, description: string, status: string, date: string) {
    this.number = name;
    this.description = description;
    this.status = status;
    this.date = date;
  }
}
