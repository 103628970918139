import { Component, ElementRef, Input, OnInit, Output,EventEmitter, OnDestroy } from '@angular/core';
import { ModalService, PropertiesService, UserService } from '@app/service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserEmailValidators } from '@app/validators/useremail.validator';
import { confirmTextValidator } from '@app/validators/confirmtext.validator';
import {TranslateService} from '@ngx-translate/core';
import { AlertService } from '@app/service/alert.service';
import { map,first } from 'rxjs/operators';
import {NgbModal, NgbActiveModal,  NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})

export class ModalComponent implements OnInit {
    @Input() id: string;
    @Input() title: string;
    @Input() modalData: any;
    @Output() childEvent = new EventEmitter();
    private element: any;
    forgotPwdForm: FormGroup;
    forgotpwdemail = "";
    modalForm: FormGroup;
    confirmText = "";
    disable = true;
    constructor(private modalService: ModalService, 
    private el: ElementRef,
    private propertyService: PropertiesService,
    private userService: UserService,
    private service: UserEmailValidators,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    public alert: AlertService,
    public activeModal: NgbActiveModal,
    public config: NgbModalConfig,
    private router: Router) {
        this.element = el.nativeElement;
        config.backdrop = 'static';
        config.keyboard = false;
    }

    ngOnInit(): void {
        let modal = this;
        //alert(JSON.stringify(this.modalData));
        if( this.modalData ) {
            if( this.modalData.category == 'deleteuser' ) {
                
               this.modalForm = this.formBuilder.group({
                  confirmtext: ['', [Validators.required,confirmTextValidator('delete')]],
               });
               console.log("HI in valid:"+this.modalForm.valid);
            } else {
               this.forgotpwdemail = "";

               this.forgotPwdForm = this.formBuilder.group({
                  forgotpwdemail: ['', [Validators.required, Validators.email],this.service.userEmailValidator()],
               });
            }
        } else {
               this.forgotpwdemail = "";

               this.forgotPwdForm = this.formBuilder.group({
                  forgotpwdemail: ['', [Validators.required, Validators.email],this.service.userEmailValidator()],
               });
        }
        // add self (this modal instance) to the modal service so it's accessible from controllers
        //this.modalService.add(this);
    }

    open(): void {
        this.element.style.display = 'block';
        document.body.classList.add('portal-modal-open');
    }

    close(): void {
        this.activeModal.close('close');
    }
    resetPwd() {
         // stop here if form is invalid
         if (this.forgotPwdForm.invalid) {
             return;
         }
        
        this.userService.sendForgotPwdLink(this.forgotPwdForm.controls.forgotpwdemail.value).pipe(first()).subscribe(
            data => {
                this.alert.success("The change password link has been sent to your email",false);
                this.activeModal.close();
                setTimeout (() => {
                    console.log("timeout");
                    this.alert.remove();
                },1000);
                
            },
            error => {
                this.alert.error("Link is not sent due some unknown error");
            },
        );
    }
    
    deleteData() {
    
        if( this.modalData && this.modalData.category == 'deleteuser' ) {
            if (this.modalForm.invalid) {
                 return;
             }
            //alert(this.modalData.value.id);
            this.userService.delete(this.modalData.value.id).pipe(first()).subscribe(
                data => {
                  this.activeModal.close('success');                     
                },
                error => {
                    this.alert.error("User is not deleted due to some unknown error");
                },
            );
        }
    }


}
