import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SidepanelComponent } from '@app/sidepanel/sidepanel.component';
import { PropertiesService } from '@app/service/properties.service';
import { AuthenticationService } from '@app/service/authentication.service';
import { map,first } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import { featureMap } from '@app/config';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  url = "";
  customerId = "";
  classname = "";
  show = true;
  color = "#D4E3FF";
  mySubscription = false;
  activeUrl = "";
  sidepaneldata: any;
  featureMap: any;
  feature: any;
  @Input() test;
  @Input() data;
  contractors: any;
  showSidePanel = false;
  @ViewChild(SidepanelComponent,{static: false} as any) private comp: SidepanelComponent;
  constructor(private route: Router, private activatedRoute: ActivatedRoute, private propertyService :PropertiesService, private authenticationService: AuthenticationService,public translate: TranslateService) {

    this.propertyService.getContractorList().pipe(first())
    .subscribe(
        data => {
            this.contractors = data;
        },
        error => {
        
        }
      );
      this.feature = localStorage.getItem('features');
      this.featureMap = featureMap;
      console.log(this.featureMap);
  }

  ngOnInit() {
      this.url = this.route.url;
      //alert(this.activeUrl);
      if( this.activeUrl.indexOf('property') >= 0 || ( this.activeUrl.length == 0 && this.url.indexOf('property') >= 0 ) )  {
        this.classname="active";
      } else {
        this.classname="";
      }
      //alert("Here --1"+this.test);
      

      this.customerId = this.activatedRoute.snapshot.paramMap.get('customerid');
      
      if( this.url.indexOf("property/connect") >= 0 ) {
          this.show = false;
      } else {
          this.show = true;
      }
      
  }


  getUpdatedvalue($event) {
    this.showSidePanel = $event;
  }

  applyClass(activeUrl) {
      this.activeUrl = activeUrl;
      this.ngOnInit();
  }

  reset() {
      this.classname = "";
      this.color = "#FFFFFF";
      this.feature = localStorage.getItem('features');
      console.log("in sideba"+this.feature);
  }
  
  logout() {
      this.classname = "";
      this.color = "#FFFFFF";
      this.authenticationService.logout();
  }

  OpenSidePanel() {
    console.log("1212<-->"+this.showSidePanel);
    this.sidepaneldata = {
      'category': 'job_estimate',
      'contractors': this.contractors
    }
    if( this.showSidePanel ) {
        this.showSidePanel = false;

    } else {
        this.showSidePanel = true;
        
    }
        
  }

}
