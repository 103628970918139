import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { AlertService } from '@app/service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
    @Input() alertMessage: any;
    private subscription: Subscription;
    message: any;
    screenName = "";
    loading =false;
    componentloading = false;
   
    constructor(private alertService: AlertService, public translate: TranslateService) { }

    ngOnInit() {
        //alert("Here in alertservice");
        this.subscription = this.alertService.getMessage().subscribe(message => {
            //alert("Here in alertservice");
            this.message = message;
            
            if( message !== undefined) {
                //alert(this.message.screen);
                this.screenName = message.screen;
            } else {
                this.screenName = "";
            }
            
        });

        if( this.alertMessage !== undefined ) {
            this.message = this.alertMessage;
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    
    close() {
        this.alertService.remove();
    }

}
