import { Component,
    OnInit,
    Input,
    Output,
    ElementRef,
    EventEmitter,
    HostListener,
    ViewChild} from '@angular/core';
import { map,first } from 'rxjs/operators';
import { FileDropDirective } from '@app/directives/file-drop.directive';
import { AddjobComponent } from '@app/property/addjob/addjob.component'
import { AdduserComponent } from '@app/property/adduser/adduser.component'
import { EdituserComponent } from '@app/property/edituser/edituser.component'
import { AddpropertyComponent } from '@app/property/addproperty/addproperty.component'
//import { NgForm } from '@angular/forms';
import { JobService } from '@app/service/job.service';
import { AlertService } from '@app/service/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';

@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.css'],
})
export class SidepanelComponent implements OnInit {
    width: string;
    display: string = 'none';
    submittedForm = false;
    @Input() showSidePanel = false;
    showUpload = false;
    formData:  FormData;
    Overlayelement: HTMLElement;
    classname = "";
    showToastMessage = false;

    @Input() data: any;
    @Output() valueUpdate = new EventEmitter();
    private element: any;
    category = "";
    @ViewChild(FileDropDirective) directive;
    @ViewChild(AddjobComponent) addjob:AddjobComponent;
    @ViewChild(AdduserComponent) adduser:AdduserComponent;
    @ViewChild(EdituserComponent) edituser:EdituserComponent;
    @ViewChild(AddpropertyComponent) addproperty:AddpropertyComponent;

    constructor(private el: ElementRef,
        private jobService: JobService,
        private alertService: AlertService,
        public translate: TranslateService
        ) {
        this.element = el.nativeElement;
        this.formData = new FormData();
        if( this.data !== undefined) {
            this.category  = this.data.category;
        }
    }

    ngOnInit() {
        if( this.data !== undefined) {
            this.category  = this.data.category;
        }
        console.log("In sidepanel --"+this.showSidePanel);
        if( this.showSidePanel) {
            this.openNav();
        } else {
            this.closeNav();
        }
    }

    ngAfterViewInit() {

    }

    /* Set the width of the sidebar to 250px (show it) */
    openNav() {
      console.log("Here 2");
      this.showSidePanel = true;
      this.classname = 'in-view drop-zone ';
      //this.width = "600px";
      this.display="block";
      document.getElementById('sidenav-overlay').className = "show";
      this.valueUpdate.emit(this.showSidePanel);
    }

    /* Set the width of the sidebar to 0 (hide it) */
    closeNav() {
        console.log("Here 3-1"+this.showSidePanel);
        this.classname = 'drop-zone ';
        this.showSidePanel = false;
        this.valueUpdate.emit(this.showSidePanel);
        document.getElementById('sidenav-overlay').className = "hide";
    }

    filesDropped(files: FileList) {
        this.addjob.filesDropped(files);
        //console.log('--files:', files);
        //this.files = files;
        console.log('--files:', files);
    }

    getFormValue(value) {
        
        if( value == 'success' || value == 'error') {
            this.closeNav();
        } else {
            this.submittedForm = value;
        }
    }

    submit() {
        if( this.category == 'job_estimate') {
            this.addjob.submitForm();
            this.closeNav();
        } else if( this.category == 'property') {
            this.addproperty.submitForm();
        } else if( this.category == 'user') {
            this.adduser.submitForm();
        } else if( this.category == 'edituser') {
            this.edituser.submitForm();
        }
    }

    ngOnDestroy(): void {
        this.element.remove();
    }

    removeOverlay() {
        this.showToastMessage = true;
        
    }

    closeConfirmation() {
        this.showToastMessage = false;
    }

    hideSidepanel() {
        document.getElementById('sidenav-overlay').className = "hide";
        
        this.closeNav();
    }

}
