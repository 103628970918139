import { Injectable, Inject, forwardRef } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpHeaders,
    HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ErrorHandlerService } from './error-handler.service';
import { AuthenticationService, AlertService } from '@app/service';
import { apiUrl,redirectToAfterLogin } from '@app/config';

declare var jQuery: any;

@Injectable({
    providedIn: 'root'
})

export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private router: Router,
        private errorHandler: ErrorHandlerService,
        private authService:AuthenticationService,
        private alertService: AlertService
    ) {

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token: string = localStorage.getItem('token');
        this.alertService.error('Loading',false);
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        /*if (!request.headers.has('Content-Type') ) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }*/

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        if( request.url.includes("assets/i18n") || request.url.includes("adminwebservice") ) {
            
        } else {
            request = request.clone({
                url: apiUrl + request.url
            });
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {

                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                    this.alertService.remove();

                }

                return event;
            }),
            catchError((errorResponse: HttpErrorResponse) => {
                let data = {};
                if (errorResponse instanceof HttpErrorResponse) {
                     let errorCode: number = errorResponse.status;
                     let errorMessage: string = errorResponse['error'] || errorResponse['data'] || errorResponse['body'];

                     let error = {
                         errorCode: errorCode,
                         errorMessage: errorMessage
                     };

                     this.errorHandler.setError(error);

                     // Handling auth error
                     if (errorCode === 401 || errorCode === 403) {
                         // redirect to login page
                         console.log("Redirect to login"+errorCode);
                        if( errorCode === 401  ) {
                            localStorage.removeItem('currentUser');
                            localStorage.removeItem('token');
                            this.authService.currentUserSubject.next(null);
                            this.router.navigate(['/login'], { queryParams: {errorMessage: error.errorMessage }});
                        } else {
                            if( error.errorMessage ) {
                                
                                this.router.navigate(['/error'], { queryParams: {errorMessage: "No access" }});
                            } else {
                                this.router.navigate(['/error'], { queryParams: {errorMessage: "Unknown error" }});
                            }
                        }
                     }

                    return throwError(errorResponse);
                }

                /*data = {
                    reason: error && errorResponse.error && errorResponse.error.reason ? errorResponse.error.reason : '',
                    status: errorResponse.status
                };*/
            }));


    }
}
