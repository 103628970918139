import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@app/model';
import { apiUrl, appKey } from '@app/config';
import { AuthenticationService } from '@app/service/authentication.service.ts';

export interface UserResponse {
  isExist: boolean;
  msg: string;
}


@Injectable({ providedIn: 'root' })
export class UserService {
    token: any;
    
    constructor(private http: HttpClient,private authenticationService: AuthenticationService) {
        this.token = this.authenticationService.currentUserValue;
    }

    getAll() {
        return this.http.get<User[]>(`${apiUrl}/users`);
    }

    getById(id: number) {
        return this.http.get(`${apiUrl}users/${id}`);
    }

    register(user: User) {
        return this.http.post(`register`, user);
    }

    update(user: User) {
        return this.http.put(`${apiUrl}/users/${user.id}`, user);
    }
    
    async add(userData) {
        /*var params = {
            name: userData.name,
            email: userData.email,
            password: userData.password,
            username: userData.username,
            viewproperty: userData.viewproperty,
            viewjob: userData.viewjob,
            viewdiaries: userData.viewdiaries,
            viewinvoice: userData.viewinvoice,
            viewcertificate: userData.viewcertificate,
            viewuser: userData.viewuser,
            adduser: userData.adduser,
            edituser: userData.edituser,
            deleteuser: userData.deleteuser,
            
        };*/
         var asyncResult = await  this.http.post<any>('api/v1/addUser?token='+this.authenticationService.currentUserValue,userData,{}).toPromise();
         return asyncResult;
    }
    
    async edit(userData,id) {
         var asyncResult = await  this.http.patch<any>('api/v1/editUser/'+id+'?token='+this.authenticationService.currentUserValue,userData,{}).toPromise();
         return asyncResult;
    }


    delete(id: number) {
        return this.http.delete('api/v1/deleteUser/'+id+'?token='+this.authenticationService.currentUserValue);
    }
    
    checkUsernameExist(name: string): Observable<UserResponse> {
        return this.http.get<UserResponse>(`api/v1/userNameExist?username=${name}`);
    }
    
    checkUseremailExist(name: string): Observable<UserResponse> {
        return this.http.get<UserResponse>(`api/v1/userEmailExist?useremail=${name}`);
    }
    
    sendForgotPwdLink(email: string) {
        
        var params = {email: email, application: appKey};
        return this.http.post(`api/v1/sendResetPwdLink`, params);
    }
    
    resetPassword(newPassword: string, confirmPassword: string, token: string) {
        
        var params = {newPassword: newPassword, confirmPassword: confirmPassword, token: token};
        return this.http.post(`api/v1/resetPassword`, params);
    }
    
    validateResetLink(token: string) {
        return this.http.get(`api/v1/validateResetLink?token=${token}`);
    }
    
    getFeatureList() {
       
        return this.http.get(`api/v1/listFeatures?token=${this.authenticationService.currentUserValue}&all=1`);
    }
    
    async getUserFeatures() {
        var asyncResult = await  
        this.http.get(`api/v1/getUserFeatures?token=${this.authenticationService.currentUserValue}`).toPromise();
        return asyncResult;
    }
}
