import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthenticationService } from '@app/service/authentication.service.ts';

import { User } from '@app/model';
import { apiUrl } from '@app/config';
import { saveAs } from 'file-saver';

interface JobDescription {
    name: string;
    id: number;
    description: any;
}

@Injectable({ providedIn: 'root' })
export class JobService {
    token: any;
    constructor(private http: HttpClient,private authenticationService: AuthenticationService) {
        this.token = this.authenticationService.currentUserValue;
    }

    getApiUrl(path: string) {
       let url = path + '?token='+this.token;
       return url;
    }


    getJob(customerid: any, id: any, subType: any) {
        let apiName = this.getApiUrl(`api/v1/getdata`);
        apiName += "&customerId="+customerid+"&jobestimateId="+id+"&type="+subType;//${customerid}/job/${id}
        return this.http.get(apiName);
    }

    postJob(jobData) {
        console.log(jobData);
        var params = {
            'contractor': jobData.contractor,
            'description': jobData.description.id,
            'search': jobData.search.id,
            'notes': jobData.notes,
            'priority': jobData.priority,
            'reference': jobData.reference,
            'files': jobData.files,
            'uploadfile': jobData.uploadfile
        };
        let apiName = this.getApiUrl(`api/v1/sendJob`);
        const headers = {'Content-Type': 'multipart/form-data'};
        /*var params = {
            'data': jobData,
            'file': file,
        }*/
        //return this.http.post<any>(apiName,jobData,{});
        return this.http.patch<any>(apiName,params,{ headers});
    }
    
    uploadFile(formData) {
        let apiName = this.getApiUrl(`api/v1/uploadFile`);
        return this.http.post<any>(apiName, formData, {  });
    }

    getJobReport(customerid, jobid, clientId) {
        var mediaType = 'application/pdf';
        let apiName = this.getApiUrl(`api/v1/jobs/`+customerid+`/report/`+jobid);
         apiName += '&filterParam='+clientId;
        return this.http.get(apiName);
    }

    async getJobDescription(contractor) {
        let apiName = this.getApiUrl(`api/v1/getjobdescription`);
        apiName += '&filterParam='+contractor;
        var asyncResult = await this.http.get<JobDescription>(apiName).toPromise();
        console.log('No issues, I will wait until promise is resolved..');
        return asyncResult;
        
    }

    getJobDescriptionSearch(contractor,term) {
        let apiName = this.getApiUrl(`api/v1/getjobdescription`);
        apiName += '&filterParam='+contractor+'&search='+term;
        return this.http.get(apiName);       
    }
}
