import { Component, OnInit, Input ,ViewChild, AfterViewInit, NgZone} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
//import { ViewComponent } from "@app/property/view/view.component";
import { DataService } from "@app/service/data.service";
import { BehaviorSubject } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [DataService]
})
export class HeaderComponent implements OnInit {
  //@Input() pagetitle: string;
 // @ViewChild(ViewComponent) child:ViewComponent;
  @Input() message: any;
  @Input() headerLink: string;
  @Input() headerTitle: string;
  @Input() alert: any;
  showMessage = true;
  username = '';
  constructor(private activatedRoute: ActivatedRoute,
      private router:Router,
      public translate: TranslateService
  ) {
    this.username = localStorage.getItem('userName');
  }

  ngOnInit() {
     
      this.headerTitle = "";
      
      setTimeout(function() {
       this.showMessage = false;
       
      }.bind(this), 3000);
  }

  logout() {
      console.log('logout');
      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
  }



}
